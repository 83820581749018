<template>

  <div class="contair">
    <router-view />
  </div>
</template>


<script setup>
import { onMounted } from "vue"
import { getBase } from "@/api";
import { useI18n } from 'vue-i18n'
const { locale } = useI18n();
onMounted(() => {
  init()
})
const init = () => {
  getBase().then(res => {

    const lang = res.site?.language || 'en-us';
    const arr = lang?.split('-');
    if (!localStorage.getItem('language')) {
      locale.value = arr[0];
      localStorage.setItem('language', arr[0]);
    }

    const language = localStorage.getItem('language') || arr[0];
    document.title = res.site[`siteName${language.toUpperCase()}`];
  })
}
</script>

<style lang="less">
html,
body,
#app {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-size: 12px;
  --van-primary-color: #232E40 !important;
}

p {
  padding: 0;
  margin: 0;
}

.contair {
  max-width: 450px;
  margin: auto;
  height: 100%;
  width: 100%;
}
</style>
