<template>
    <div class="page">
        <slot></slot>
    </div>
</template>

<style lang="less">
.page{
    background: linear-gradient(to bottom, #82D7D0 0%, #ffffff 100%);
    background-attachment: fixed;
    background-position: center top;
    background-size: 100% 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    .form{
        .input,.select{
            background-color: #fff;
            border-radius: 20px;
        }
    }
}
</style>