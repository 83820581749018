import md5 from 'md5'
//import JSEncrypt from 'jsencrypt'
import jsrsasign from 'jsrsasign'
import sha256 from 'js-sha256'
import qs from 'qs'

const APIKEY = '1612703a0fef43daa6d60b39fdc84164'

const APISECRET = 'e2bdb90a5aef4afeacff36cf9fcb0b72'

const VERSION = '1.0.0'

// // rsa 公钥
//const RSAPUBLICKEY = '-----BEGIN PUBLIC KEY----- MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCeGWvqt09ISfeo64TY5gP4Qn89ovhEYWidjWrj5V6JkG/uVi2/RLZcD0gmmIFHfbaT8I3QS1u1k0MHLjFFMlQasJOdPr2N8sKmjSsHf/fnhWLKr/uTGKLd/8FdVXQbnU4EiF/X3b3eJaeS4kGwsxXZqRcbImLaTd1gTbaiXLDrwQIDAQAB -----END PUBLIC KEY-----'

// rsa 私钥
const RSAPRIVATEKEY =
  '-----BEGIN PRIVATE KEY----- MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAIkaoE7FIxW65qea5hqcFzHp4yy27I6zu0JQbA2RERvzR7FcwPpQ27RjR6uzcDY9zyvmD0Hsm/Ru7Lru3X7ZqqLm20BblXE2x00tFFIopPQukqcCb3qWvMRM10WMIgl+upJ+8trtuYSVEV0qV73LYzcCE86joJFOxhA10ZTeQi4JAgMBAAECgYBAkvTcBMegjXXjjPZBpDDchm9D+Sfzkj1pX111ClVHl861f504iGriTDxOZ2Y7deMkpueawik1+CSxap2SCEzwV1mLRL+Rik0MR+HB+32azjdDat4lmQcQwpRVmENk6r5U5rz74nEAHWd4VCeElOb7LPY0Gh79SgsxOgRoqBasfQJBAOElZWp2WxfIDkom98AnDqrOmc9qc+zngpz8q25Q356aAjZpA8hvM3SqGUPK4++5gyNvRBsXQJXyNRAyWRmV25cCQQCb5IbZpLpT/ROAwUPny11zXESVS29rRL/17Rhse30cAVlpeTTcP8VkULEwaAmmns/6MwrEW5KMYt6UK8niUfdfAkEAsPfM/F6lgU7ddImmqWHJ1JZqLa+WCTt73dt5AFAPRUH2xT/MGmrVvjjJfRDoExD1NBAML2MagAIOlXmNsV+qZwJAJjYd17tI8M1Xunek7Wwru9sh9HZJGUIAy4cSNYUPZY3vHKtZCaOX/r7Ir9TQiMbks69PNFI92atiY29YTOFKgQJAIT5zZUIDHIw5bRG5f4KGhKrTM7kDMDrDy2Xl07RaitYHW2Nq//Sdo9dxnPF4AEOgWpwRgAeKf86Osd+fpbVedw== -----END PRIVATE KEY-----'

export default function apiSign(config) {
  const timestamp = new Date().getTime()
  const params = paramsParse(config.data)

  const apiNonce = createApiNonce()
  const fullPath = `${config.url}`
  config.headers['X-Api-Terminal'] = getDeviceType()
  config.headers['X-Api-Language'] = localStorage.getItem('language')||"zh"
  config.headers['X-Api-Version'] = VERSION
  config.headers['X-Api-Key'] = APIKEY
  config.headers['X-Api-Timestamp'] = timestamp
  config.headers['X-Api-Nonce'] = apiNonce
  config.headers['X-Api-Signature-Params'] = params.keys.join(',')
  config.headers['X-Api-Signature-Method'] = 'HmacSHA256'

  config.headers['X-Api-Signature'] = createSignature(
    params.urlParams,
    apiNonce,
    fullPath,
  ) //;

  config.headers['X-Api-Digest'] = createDigest(params.urlParams)

  if (localStorage.getItem('token')) {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
  }
}

const createApiNonce = (function () {
  let seqNum = 0
  return function () {
    const timestamp = new Date().getTime()
    return md5(APIKEY + timestamp + seqNum++)
  }
})()

function createSignature(urlParams, apiNonce, fullPath) {
  return encodeURIComponent(
    sha256.hmac(APISECRET, `${urlParams}${VERSION}${apiNonce}${fullPath}`),
  )
}
function createDigest(urlParams) {
  return encodeURIComponent(rsaSign(`${urlParams}`))
}
function getDeviceType() {
  const userAgent = navigator.userAgent
  const isIOS = /(iPod|iPhone|iPad)/.test(userAgent)
  if (isIOS) {
    return 'iOS'
  }
  if (userAgent.indexOf('Android') > -1) {
    return 'Android'
  }
  return 'Other'
}
//RSA加密
// function rsaNcrypt(str) {
//   const encryptStr = new JSEncrypt()
//   encryptStr.setPublicKey(RSAPUBLICKEY)
//   return encryptStr.encrypt(str)
// }

//RSA签名
function rsaSign(str) {
  const signature = new jsrsasign.KJUR.crypto.Signature({
    alg: 'MD5withRSA',
    prvkeypem: RSAPRIVATEKEY,
  })
  signature.updateString(str)
  // 签名返回结果是16进制字符串，注意转码
  const res = signature.sign()
  return jsrsasign.hextob64(res)
}

function paramsParse(params) {
  params = sortAscii(params)
  const keys = Object.keys(params)
  // encode=false 中文不转码
  const urlParams = qs.stringify(params, { encode: true })
  return {
    keys,
    urlParams,
  }
}

/** 按ascii码从小到大排序
 * @param obj
 * @returns {object}
 */
function sortAscii(obj = {}) {
  const arr = []
  let num = 0
  for (const i in obj) {
    arr[num] = i
    num++
  }
  const sortArr = arr.sort()
  const sortObj = {} // 完成排序值
  for (const i in sortArr) {
    sortObj[sortArr[i]] = obj[sortArr[i]]
  }
  return sortObj
}
