import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/login/login.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () =>
      import(
        /* webpackChunkName: "register" */ '../views/register/register.vue'
      ),
  },
  {
    path: '/language',
    name: 'language',
    component: () =>
      import(
        /* webpackChunkName: "language" */ '../views/language/language.vue'
      ),
  },
  {
    path: '/deposit',
    name: 'deposit',
    component: () =>
      import(/* webpackChunkName: "deposit" */ '@/views/deposit/index.vue'),
  },
  {
    path: '/deposit/method',
    name: 'depositMethod',
    component: () =>
      import(/* webpackChunkName: "method" */ '@/views/deposit/method.vue'),
  },
  
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () =>
      import(/* webpackChunkName: "withdraw" */ '@/views/withdraw/index.vue'),
  },
  {
    path: '/withdraw/record',
    name: 'withdrawRecord',
    component: () =>
      import(
        /* webpackChunkName: "withdrawRecord" */ '@/views/withdraw/record.vue'
      ),
  },
  {
    path: '/address',
    name: 'address',
    component: () =>
      import(/* webpackChunkName: "address" */ '@/views/address/index.vue'),
  },
  {
    path: '/bank',
    name: 'bank',
    component: () =>
      import(/* webpackChunkName: "bank" */ '@/views/bank/index.vue'),
  },
  {
    path: '/password',
    name: 'password',
    component: () =>
      import(/* webpackChunkName: "password" */ '@/views/password/index.vue'),
  },
  {
    path: '/password/assets',
    name: 'assetsPassword',
    component: () =>
      import(
        /* webpackChunkName: "assetsPassword" */ '@/views/password/assets.vue'
      ),
  },
  {
    path: '/detail',
    name: 'detail',
    component: () =>
      import(/* webpackChunkName: "detail" */ '@/views/detail/index.vue'),
  },
  {
    path: '/submit',
    name: 'submit',
    component: () =>
      import(/* webpackChunkName: "submit" */ '@/views/submit/index.vue'),
  },
  {
    path: '/test',
    name: 'test',
    component: () =>
      import(/* webpackChunkName: "test" */ '../views/test/index.vue'),
  },
  {
    path: '/',
    name: 'layout',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/home/layout.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/home/index/index.vue'),
      },
      {
        path: '/order',
        name: 'order',
        component: () =>
          import(
            /* webpackChunkName: "order" */ '@/views/home/order/index.vue'
          ),
      },
      {
        path: '/task',
        name: 'task',
        component: () =>
          import(/* webpackChunkName: "task" */ '@/views/home/task/index.vue'),
      },
      {
        path: '/service',
        name: 'service',
        component: () =>
          import(
            /* webpackChunkName: "service" */ '@/views/home/service/index.vue'
          ),
      },
      {
        path: '/mine',
        name: 'mine',
        component: () =>
          import(/* webpackChunkName: "mine" */ '@/views/home/mine/index.vue'),
      },
    ],
  },

  {
    path: '/rule',
    name: 'rule',
 
    component: () =>
      import(/* webpackChunkName: "rule" */ '../views/rule/index.vue'),
  },
  {
    path: '/platform',
    name: 'platform',
 
    component: () =>
      import(/* webpackChunkName: "platform" */ '../views/platform/index.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
const whitelist = ['/language', '/register', '/login']
router.beforeEach((to, from, next) => {
  // console.log(to, from)
  const token = localStorage.getItem('token')

  if (to.path == '/login' && token) {
    next('/')
  }
  if (!whitelist.includes(to.path) && !token) {
    next('/login')
  }
  next()
})
export default router
